import React from "react"
import { FormattedMessage } from "react-intl"

import { Row, Col } from "antd"

import Seo from "../components/seo"
import Icon from "../components/iconfontCpt"
import Layout from "../components/layout"

import "../style/doc.scoped.scss"

import { allToolsMenu } from "../utils/config.js"

const Doc = ({ pageContext }) => {
  const pageLang = pageContext.lang

  return (
    <Layout
      pageLang={pageLang}
      showHeader={true}
      showFooter={true}
      useTransparentTheme={true}
      customClass="use-translucent-theme"
    >
      <Seo title="MogDB: DOC" />
      <div className="w-banner-container">
        <div className="w-banner-box">
          <div className="w-banner-text">
            <FormattedMessage id="productDoc.pageTitle" />
          </div>
        </div>
      </div>
      <div className="doc-container">
        {allToolsMenu.map((item, idx) => (
          <div key={idx}>
            <div className="type-title">{item[pageLang]}</div>
            <Row gutter={20}>
              {item.menu.map((m, i) => (
                <Col span={24} md={12} key={i}>
                  <a href={m[pageLang] && m[pageLang].link} target="_blank">
                    <div
                      className={`product-box doc-product-box ${m.key} ${
                        !m[pageLang].link ? "disabled" : ""
                      }`}
                    >
                      <div className={`w-product-icon w-product-${m.key}-icon`}>
                        <Icon type={`icon-${m.key}`} size={20} />
                      </div>
                      <div className="product-title">{m.label}</div>
                      <div className="product-desc">
                        {m[pageLang] && (m[pageLang].desc || m[pageLang].title)}
                      </div>
                      <div className="more-link">
                        <span className="more-text">
                          <FormattedMessage id="productDoc.moreLink" />
                        </span>
                        <Icon type="icon-more" />
                      </div>
                    </div>
                  </a>
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </div>
    </Layout>
  )
}
export default Doc
